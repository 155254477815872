@use "../variables/screens" as *;
@use "../variables/mixins" as *;

:where(*) {
  font-family: "barlow", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

:where(.black) :where(*) {
  font-family: "barlow", sans-serif;
  font-weight: 400;
}

:where(*) :where(*) {
  font-size: inherit;
  font-weight: inherit;
}

strong {
  font-weight: 700;
}

html {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  scroll-padding-top: var(--scroll-padding);
}

body {
  color: hsl(var(--main-color));
  background-color: hsl(var(--main-background));
  width: 100%;
  margin: 0;
  padding: 0;
  font-weight: 300;
  transition: all 0.3s ease;
  overflow-x: hidden;

  & > section {
    text-align: center;

    &:nth-child(odd) {
      background-color: hsl(var(--lightgrey));
    }
  }

  &.black {
    --main-color: var(--white);
    --main-background: var(--black);
    --main-shadow: var(--green-shadow);
    --main-heading: var(--white);
    --brand-color: var(--green);
    --main-outline: var(--white);

    font-weight: 400;

    > section {
      &:nth-child(odd) {
        background-color: hsl(var(--main-background));
      }
    }
  }
}

a {
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 0.3s ease;
  color: hsl(var(--blue));
  font-weight: 400;

  &:hover {
    color: hsl(var(--blue));
    border-bottom-color: hsl(var(--blue));
  }
}

p {
  color: hsl(var(--main-color));
  line-height: 1.4;
  margin-top: 0;

  &:last-of-type {
    margin-bottom: 0;
  }
}

input,
textarea,
select {
  &.error {
    border-color: hsl(var(--red));
  }
}

img {
  max-width: 100%;
  height: auto;
}

br {
  &.enable-bp {
    display: none;

    &.all {
      display: block;
    }

    &.b479-down {
      @include b-479 {
        display: block;
      }
    }

    &.b479-up {
      @include b-479-up {
        display: block;
      }
    }

    &.b600-down {
      @include b-600 {
        display: block;
      }
    }

    &.b600-up {
      @include b-600-up {
        display: block;
      }
    }

    &.b767-down {
      @include b-767 {
        display: block;
      }
    }

    &.b767-up {
      @include b-767-up {
        display: block;
      }
    }

    &.b991-down {
      @include b-991 {
        display: block;
      }
    }

    &.b991-up {
      @include b-991-up {
        display: block;
      }
    }

    &.b1199-down {
      @include b-1199 {
        display: block;
      }
    }

    &.b1199-up {
      @include b-1199-up {
        display: block;
      }
    }

    &.b479-600 {
      @include between-479-600 {
        display: block;
      }
    }

    &.b600-767 {
      @include between-600-767 {
        display: block;
      }
    }

    &.b767-991 {
      @include between-767-991 {
        display: block;
      }
    }

    &.b991-1199 {
      @include between-991-1199 {
        display: block;
      }
    }
  }
}

.container {
  max-width: var(--container-width);
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;

  @include b-600-up {
    padding-left: 0;
    padding-right: 0;
    margin: auto;
  }
}

input,
select {
  border-radius: 8px;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid hsl(var(--gray));

  &.button {
    border-radius: 12px;
    padding: 1rem 3rem;
    width: initial;
    border: none;
  }
}

.response {
  line-height: 1;
  color: hsl(var(--red));
}

.footnote {
  font-size: 12px;
  background-color: hsl(var(--lightgrey));
  // position: absolute;
  // bottom: 0;
  // left: 0;
  max-width: 350px;
  padding: 1rem;
}

.button-wrapper {
  text-align: center;
  display: grid;
  justify-content: center;
  justify-items: center;

  .button {
    margin-bottom: 0.5rem;
  }

  input {
    font-weight: 500;
    font-size: 20px;
    padding: 1rem 3rem;
    border-radius: 12px;
    width: initial;
    background: linear-gradient(100deg, #f79d82 21.16%, #ffd49c 114.67%);
    background-size: 200% 100%;
    background-position: 0% 50%;
    transition: background-position 0.3s ease;
    border: none;
    cursor: pointer;

    &:hover {
      background-position: 100% 50%;
    }
  }

  .spinner {
    border: 3px solid #0000001a;
    border-top: 3px solid #000;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.6s linear infinite;
    display: inline-block;
  }
}

// Aspect ratio hack
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
