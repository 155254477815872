@use "../variables/mixins" as *;

@include font-face('PlusJakartaSans', '../fonts/PlusJakartaSans-100', 100, "normal", 'ttf');
@include font-face('PlusJakartaSans', '../fonts/PlusJakartaSans-300', 300, "normal", 'ttf');
@include font-face('PlusJakartaSans', '../fonts/PlusJakartaSans-400', 400, "normal", 'ttf');
@include font-face('PlusJakartaSans', '../fonts/PlusJakartaSans-500', 500, "normal", 'ttf');
@include font-face('PlusJakartaSans', '../fonts/PlusJakartaSans-700', 700, "normal", 'ttf');

@include font-face('barlow', '../fonts/Barlow-100', 100, "normal", 'ttf');
@include font-face('barlow', '../fonts/Barlow-200', 200, "normal", 'ttf');
@include font-face('barlow', '../fonts/Barlow-300', 300, "normal", 'ttf');
@include font-face('barlow', '../fonts/Barlow-400', 400, "normal", 'ttf');
@include font-face('barlow', '../fonts/Barlow-500', 500, "normal", 'ttf');
@include font-face('barlow', '../fonts/Barlow-600', 500, "normal", 'ttf');
@include font-face('barlow', '../fonts/Barlow-700', 700, "normal", 'ttf');