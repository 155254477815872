$b-380: 380px;
$b-400: 400px;
$b-479: 479px;
$b-600: 600px;
$b-767: 767px;
$b-991: 991px;
$b-1024: 1024px;
$b-1199: 1199px;
$b-1439: 1439px;
$b-1919: 1919px;

// =============================================================================
// Max widths
// =============================================================================
@mixin b-380 {
    @media (max-width: ($b-380)) {
        @content;
    }
}

@mixin b-400 {
    @media (max-width: ($b-400)) {
        @content;
    }
}

@mixin b-479 {
    @media (max-width: ($b-479)) {
        @content;
    }
}

@mixin b-600 {
    @media (max-width: ($b-600)) {
        @content;
    }
}

@mixin b-767 {
    @media (max-width: ($b-767)) {
        @content;
    }
}

@mixin b-991 {
    @media (max-width: ($b-991)) {
        @content;
    }
}

@mixin b-1024 {
    @media (max-width: ($b-1024)) {
        @content;
    }
}

@mixin b-1199 {
    @media (max-width: ($b-1199)) {
        @content;
    }
}

@mixin b-1439 {
    @media (max-width: ($b-1439)) {
        @content;
    }
}

@mixin b-1919 {
    @media (max-width: ($b-1919)) {
        @content;
    }
}

// =============================================================================
// Min widths
// =============================================================================
@mixin b-380-up {
    @media (min-width: ($b-380 + 1)) {
        @content;
    }
}

@mixin b-479-up {
    @media (min-width: ($b-479 + 1)) {
        @content;
    }
}

@mixin b-600-up {
    @media (min-width: ($b-600 + 1)) {
        @content;
    }
}

@mixin b-767-up {
    @media (min-width: ($b-767 + 1)) {
        @content;
    }
}

@mixin b-991-up {
    @media (min-width: ($b-991 + 1)) {
        @content;
    }
}

@mixin b-1024-up {
    @media (min-width: ($b-1024 + 1)) {
        @content;
    }
}

@mixin b-1199-up {
    @media (min-width: ($b-1199 + 1)) {
        @content;
    }
}

@mixin b-1439-up {
    @media (min-width: ($b-1439 + 1)) {
        @content;
    }
}

@mixin b-1919-up {
  @media (min-width: ($b-1919 + 1)) {
      @content;
  }
}

// =============================================================================
// Between breakpoints
// =============================================================================
@mixin between-479-600 {
    @media (min-width: ($b-479 + 1)) and (max-width: ($b-600)) {
        @content;
    }
}

@mixin between-479-767 {
    @media (min-width: ($b-479 + 1)) and (max-width: ($b-767)) {
        @content;
    }
}

@mixin between-600-767 {
    @media (min-width: ($b-600 + 1)) and (max-width: ($b-767)) {
        @content;
    }
}

@mixin between-767-991 {
    @media (min-width: ($b-767 + 1)) and (max-width: ($b-991)) {
        @content;
    }
}

@mixin between-767-1024 {
    @media (min-width: ($b-767 + 1)) and (max-width: ($b-1024)) {
        @content;
    }
}

@mixin between-991-1024 {
    @media (min-width: ($b-991 + 1)) and (max-width: ($b-1024)) {
        @content;
    }
}

@mixin between-991-1199 {
    @media (min-width: ($b-991 + 1)) and (max-width: ($b-1199)) {
        @content;
    }
}

@mixin between-1024-1199 {
    @media (min-width: ($b-1024 + 1)) and (max-width: ($b-1199)) {
        @content;
    }
}

@mixin between-1199-1439 {
    @media (min-width: ($b-1199 + 1)) and (max-width: ($b-1439)) {
        @content;
    }
}
