@use "./variables/screens" as *;

@use "main";

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.langs-wrapper {
  position: absolute;
  right: 5px;
  top: 5px;

  .langs {
    display: grid;

    .lang {
      display: none;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;

      &:hover {
        border-bottom-color: transparent;
      }

      &.active {
        display: block;
      }

      img {
        width: 32px;
      }
    }
  }
}

body {
  .registration-wrapper {
    width: 100%;
    height: 100vh;
    background-image: url("../images/register-page.webp");
    background-size: cover;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    .form-wrapper {
      overflow-x: hidden;
      overflow-y: hidden;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      height: 98%;
      background: transparent;
      border-radius: 10px;
      display: flex;
      //animation
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;

      @media (min-height: 900px) {
        height: auto;
      }

      .progress {
        background-color: #00162c;
        flex: 0.3;
        width: 100%;
        padding: 2rem 2rem;
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 3rem;

        @include b-767-up {
          display: flex;
        }

        .items {
          display: grid;
          gap: 3rem;

          .item {
            display: flex;
            gap: 1rem;
            align-items: center;

            .number {
              width: 55px;
              height: 55px;
              border: 2px solid hsl(var(--white));
              border-radius: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 500;
              font-size: 22px;
              position: relative;

              &::after {
                content: "";
                height: 3rem;
                width: 2px;
                position: absolute;
                top: 100%;
                background-color: hsl(var(--white));
              }

              &.part-2 {
                &::after {
                  content: initial;
                }
              }

              &.active {
                background-color: hsl(var(--white));
                color: #00162c;
              }

              &.completed {
                background-color: #62b45dcc;
              }
            }

            .content {
              display: grid;

              .title-wrapper {
                padding: 0 2rem;

                .title {
                  font-size: 24px;
                }

                .description {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .form {
        width: 100%;
        background-color: hsl(var(--white));
        color: hsl(var(--black));
        text-align: center;
        padding: 2rem 0 0 0;
        flex: 1;
        overflow-y: auto;
        position: relative;

        @include b-767-up {
          flex: 0.7;
        }

        h1 {
          font-size: 26px;
        }

        .type {
          display: flex;

          > * {
            font-size: 16px;
            font-weight: 500;
            background-color: hsl(var(--white));
            color: #000;
            width: 100%;
            padding: 1rem;
            border: 1px solid #000;
            cursor: pointer;
          }

          .individual {
            border-radius: 12px 0 0 12px;
          }

          .corporate {
            border-radius: 0 12px 12px 0;
          }

          .active {
            background-color: #000;
            color: white;
          }
        }

        .register-form {
          display: grid;
          gap: 1rem;
          padding: 2rem;

          .hidden {
            display: none;
          }

          .half {
            display: flex;
            gap: 0.5rem;

            > * {
              flex: 0.5;
            }

            &.phone {
              select {
                flex: 0.3;
              }

              input {
                flex: 0.7;
              }
            }
          }

          .confirm {
            display: flex;
            text-align: initial;

            input {
              width: initial;
            }
          }

          .disclaimer {
            font-size: 12px;
          }

          #currency {
            display: none;
          }

          .message {
            color: hsl(var(--red));
          }
        }
      }

      .footnote {
        max-width: initial;
      }
    }
  }

  .login-wrapper {
    width: 100%;
    height: 100vh;
    background-image: url("../images/register-page.webp");
    background-size: cover;
    color: hsl(var(--black));
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    @include b-479-up {
      padding: initial;
    }

    .form-wrapper,
    .forget-wrapper {
      overflow-x: hidden;
      overflow-y: auto;
      max-width: 1000px;
      margin-left: auto;
      margin-right: auto;
      height: 98%;
      background: hsl(var(--white));
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      position: relative;
      gap: 2rem;
      padding: 2rem;
      //animation
      opacity: 1;
      animation-name: fadeInOpacity;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.3s;

      @media (min-height: 767px) {
        height: auto;
      }
      a {
        width: max-content;
      }

      .logo {
        text-align: center;

        img {
          @include b-479-up {
            min-width: 250px;
          }
        }
      }

      .login-form {
        width: 100%;
        display: grid;
        gap: 1rem;
        font-weight: 400;
        padding: 0;

        .content-wrapper {
          display: grid;
          gap: 0.5rem;

          &.error {
            background-color: red;
          }
        }

        .response {
          color: hsl(var(--red));
          transition: 0.3s ease;
          text-align: center;
        }

        .button-wrapper {
          text-align: center;
          display: grid;
          justify-content: center;
          justify-items: center;

          .spinner {
            border: 3px solid rgba(0, 0, 0, 0.1);
            border-top: 3px solid #000;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            animation: spin 0.6s linear infinite;
            display: inline-block;
          }
        }
      }
    }

    .forget-wrapper {
      display: none;

      .forget-form {
        padding: 0rem 3rem;
        width: 100%;
        display: grid;
        gap: 1rem;
        font-weight: 400;
        justify-content: center;
      }
    }
  }
}
